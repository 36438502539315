import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import HomePage from "./pages/HomePage";
import Team from "./pages/Team";
import Partner from "./pages/Partner";
import NewsList from "./pages/NewsList";
import Layout from "./components/Layout";
import Impress from './pages/Impress';
import Privacy from './pages/Privacy';
import Downloads from './pages/Downloads';
import Developer from './pages/apply/Developper';
import Content from './pages/apply/Content';
import Moderator from './pages/apply/Moderator';
import Builder from './pages/apply/Builder';

const App = () => {
    return (
        <BrowserRouter>

            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path='team' element={<Team />} />
                    <Route path='partner' element={<Partner />} />
                    <Route path='news' element={<NewsList />}>
                    </Route>
                    <Route path='downloads' element={<Downloads />}/>
                    <Route path='developer' element={<Developer />} />
                    <Route path='content' element={<Content />} />
                    <Route path='moderator' element={<Moderator />} />
                    <Route path='builder' element={<Builder />} />
                    <Route path='imprint' element={<Impress />}/>
                    <Route path='privacy' element={<Privacy />}/>
                </Route>
                <Route path="*" element={<Navigate replace to="/" />}/>
            </Routes>

        </BrowserRouter>
    );
}

export default App;